.parent{
  max-width: 99.5%;
}

.table_row {
   /* width: 100%; */
  
}
#parent_div_1 td:last-of-type{
  border-right:0px;
}

#parent_div_1 tr:last-of-type, #parent_div_2  tr:last-of-type {
  border-bottom: 1px solid #c1c3d1;
}

#parent_div_1 tr:first-of-type, #parent_div_2  tr:first-of-type {
  border-top: none;
}

#parent_div_1  tbody tr:first-of-type td, #parent_div_2  tbody tr:first-of-type td {
  padding-top: 8px;
}
#parent_div_2  td:last-of-type{
  /* border-right: 1px solid rgba(27,55,88,.2); */
}
#parent_div_1 td, #parent_div_2 td {
  font-weight: 500;
  font-size: 11px;
  white-space: nowrap;
  padding: 6px 10px 6px 10px;
  vertical-align: middle;
  border-right: 1px solid rgba(27,55,88,.2);
  border-left: 1px solid rgba(27,55,88,.2);
  height: 30px;
}

#table_1{
  width:100%;
  
}

#table_2{
  position: relative;
  /* width:94%; */
}

table {
    border-collapse: collapse;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

#parent_div_1 tr, #parent_div_2 tr {
  border-top: 1px solid #c1c3d1;
  color: #325781;
  font-weight: 400;
  text-shadow: 0 1px 1px hsla(0,0%,100%,.1);
  
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

/* #main_table>.shadow{
  -webkit-box-shadow: inset 26px 2px 42px -26px rgba(0,0,0,0.75);
-moz-box-shadow: inset 26px 2px 42px -26px rgba(0,0,0,0.75);
box-shadow: inset 26px 2px 42px -26px rgba(0,0,0,0.75);
} */


.sales_in_contract{
  
  /* //background: #a8e0e3; */
  /* -webkit-box-shadow: 5px 2px 5px -2px rgb(169, 169, 169);
-moz-box-shadow: 5px 2px 5px -2px rgb(169, 169, 169);
box-shadow: 5px 2px 5px -2px rgb(169, 169, 169); */
}

.sales_in_contract td{
  font-weight: bolder !important;
  background-color:rgba(168,224,227,0.5);
}
.sales_in_contract:hover td{
  background-color:rgba(227,255,163,0.6);
  color:#000;
}

.clickable {
  cursor: pointer;
}
#parent_div_1{
  display: block;
  overflow-y: scroll;
  
  max-height: 86.2vh;
  background: white;
  z-index: 1;
  scrollbar-width: none;
  -ms-overflow-style: none;
  box-shadow: 5px 0px 5px -2px #a9a9a9;
  /* width:100%; */
}


#parent_div_2{
  display: block; 
  overflow-x: scroll; 
  max-height: 86.8vh;
  /* width:100%; */
  /* height: 87vh; */
 
  
}
.sales_table_2{
  width:100% !important;
}

#first_td_container{
  /* box-shadow: 8px 0px 5px -2px #a9a9a9; */
  vertical-align: top;
  /* min-width: 20%; */
}

#parent_div_2::-webkit-scrollbar {
  -webkit-appearance: none;
}

#parent_div_2::-webkit-scrollbar:vertical {
  width: 8px;
}

#parent_div_2::-webkit-scrollbar:horizontal {
  height: 8px;
  background: none repeat scroll 0 0 transparent;
  position: fixed;
}

#parent_div_2::-moz-scrollbar {
  -webkit-appearance: none;
}

#parent_div_2::-moz-scrollbar:vertical {
  width: 6px;
}

#parent_div_2::-moz-scrollbar:horizontal {
  height: 6px;
  background: none repeat scroll 0 0 transparent;
  position: fixed;
}


#parent_div_2 table tr:hover td:first-child{
  /* box-shadow: inset 3px 0px 4px 0px rgb(169, 169, 169); */

}

#parent_div_2 {
  right: 0;
  bottom: 0;
  border-collapse: collapse;
  border-spacing: 2px;
  border-color: grey;
  padding-right:1px;
}


table {
position: relative;
border-collapse: collapse;
}

td,
th {
padding: 0.25em;
}

thead th {
    
    position: sticky;
    color: #fff;
    background: #2fbabe;
    font-size: 1rem;
    font-weight: 500;
    text-shadow: 0 1px 1px rgba(0,0,0,.1);
    top: 0;
    white-space: nowrap;
    padding: 6px 6px;
    vertical-align: middle;
    margin-top: 43px;
    z-index: 2;
    
}

thead th:last-of-type{
  /* border-right: 1px solid rgb(47, 186, 190); */
}

table.table_row td {
  padding:0px;
}


#parent_div_1 th.contract-price {
  background: #325781;
  color: #fff;
}
#parent_div_2 th.contract-price {
  background: #325781;
  color: #fff;
}
#parent_div_1 td.contract-price {
  background: rgb(114, 236, 232, 0.2);
  color: #1B3758;
  border-right: 1px solid rgb(27, 55, 88, 0.2);
  border-left: 1px solid rgb(27, 55, 88, 0.2);
}
#parent_div_2 td.contract-price {
  background: rgb(114, 236, 232, 0.2);
  color: #1B3758;
  border-right: 1px solid rgb(27, 55, 88, 0.2);
  border-left: 1px solid rgb(27, 55, 88, 0.2);
}

#parent_div_1  tr:hover td, #parent_div_2  tr:hover td{
  background-color:rgba(227,255,163,0.6);
  color:#000;
}

#parent_div_1::-webkit-scrollbar {
  width: 0px;
  
  
}
#parent_div_1::-moz-scrollbar {
  width: 0px;
}

